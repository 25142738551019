import { getStats, GetUserDetails, SwitchApi } from '../api/ApiService';
import {
  BUSINESSUSERDATA,
  CURRENTUSER,
  GENERATE_TOKEN,
  STATISTICS,
  SWITCHACCOUNT,
  USERDETAILS,
} from '../constants';
import { validateToken } from '../utilities';
import { loadingAction } from './loadingAction';

export const userDetailsActionFn = () => {
  return async (dispatch) => {
    dispatch(loadingAction(true));
    const response = await validateToken();

    dispatch({ type: GENERATE_TOKEN, payload: response });
    return GetUserDetails()
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: USERDETAILS, payload: response });
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        throw error;
      });
  };
};

export const switchAccountActionFn = (data) => {
  return async (dispatch) => {
    dispatch(loadingAction(true));
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });

    return SwitchApi(data)
      .then((response) => {
        dispatch(loadingAction(false));
        if (response && response.status && response.status === 200) {
          return dispatch({ type: SWITCHACCOUNT, payload: response.data });
        }
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        throw error;
      });
  };
};

export const currentUserActionFn = (data) => {
  return {
    type: CURRENTUSER,
    payload: { data },
  };
};

export const businessAccountActionFn = (data) => {
  return {
    type: BUSINESSUSERDATA,
    payload: {
      data,
    },
  };
};

export const userStatsActionFn = (data) => {
  return async (dispatch) => {
    dispatch(loadingAction(true));
    const response = await validateToken();

    dispatch({ type: GENERATE_TOKEN, payload: response });
    return getStats(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: STATISTICS, payload: response });
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        throw error;
      });
  };
};
