import {
  FilterTypes,
  getTopReceiver,
  getTopSenders,
  getTransactionById,
  getTransactions,
  merchantTransactionStats,
  paymentvsRequest,
  revenueGraph,
  transactionsByType,
} from '../api/ApiService';
import {
  FILTERTYPE,
  GENERATE_TOKEN,
  GETTRANSACTIONS,
  MERCHANTSTATS,
  PAYMENTVSREQUEST,
  REVENUEGRAPH,
  TOPRECEIVERS,
  TOPSENDERS,
  TRANSACTION,
  TRANSACTIONTYPE,
  GET_EXPORT_TRANSACTIONS,
  GET_PDF_TRANSACTIONS_DETAILS,
} from '../constants';
import { validateToken } from '../utilities';
import { loadingAction } from './loadingAction';

export const getTransactionActionFn = (data) => {
  return async (dispatch) => {
    dispatch(loadingAction(true));

    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });

    return getTransactions(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: GETTRANSACTIONS, payload: response });
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        throw error;
      });
  };
};

export const getTransactionTypeActionFn = (data) => {
  return async (dispatch) => {
    dispatch(loadingAction(true));

    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });

    return transactionsByType(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: TRANSACTIONTYPE, payload: response });
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        throw error;
      });
  };
};

export const getExportTransactionAction = (data) => {
  return async (dispatch) => {
    dispatch(loadingAction(true));

    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });

    return getTransactions(data)
      .then((response) => {
        dispatch(loadingAction(false));

        if (response && response.status && response.status === 200) {
          return dispatch({
            type: GET_EXPORT_TRANSACTIONS,
            payload: response.data,
          });
        }
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        throw error;
      });
  };
};

export const getPdfTransactionDetailAction = (transactionId) => {
  return async (dispatch) => {
    dispatch(loadingAction(true));

    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });

    return getTransactionById(transactionId)
      .then((response) => {
        dispatch(loadingAction(false));
        if (response && response.status && response.status === 200) {
          return dispatch({
            type: GET_PDF_TRANSACTIONS_DETAILS,
            payload: response.data,
          });
        }
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        throw error;
      });
  };
};

export const getRevenueActionFn = (data) => {
  return async (dispatch) => {
    dispatch(loadingAction(true));

    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });

    return revenueGraph(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: REVENUEGRAPH, payload: response });
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        throw error;
      });
  };
};

export const getTransactionByIdActionFn = (id) => {
  return async (dispatch) => {
    dispatch(loadingAction(true));

    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });

    return getTransactionById(id)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: TRANSACTION, payload: response });
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        throw error;
      });
  };
};

export const getTopReceiverActionFn = () => {
  return async (dispatch) => {
    dispatch(loadingAction(true));

    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });

    return getTopReceiver()
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: TOPRECEIVERS, payload: response });
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        throw error;
      });
  };
};

export const getTopSendersActioFn = () => {
  return async (dispatch) => {
    dispatch(loadingAction(true));

    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });

    return getTopSenders()
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: TOPSENDERS, payload: response });
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        throw error;
      });
  };
};

export const paymentVsRequestActionFn = (data) => {
  return async (dispatch) => {
    dispatch(loadingAction(true));

    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });

    return paymentvsRequest(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: PAYMENTVSREQUEST, payload: response });
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        throw error;
      });
  };
};

export const getFilterTypeActionFn = () => {
  return async (dispatch) => {
    dispatch(loadingAction(true));

    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });

    return FilterTypes()
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: FILTERTYPE, payload: response });
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        throw error;
      });
  };
};

export const merchantTransactionStatisticsActionFn = (data) => {
  return async (dispatch) => {
    dispatch(loadingAction(true));

    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });

    return merchantTransactionStats(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: MERCHANTSTATS, payload: response });
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        throw error;
      });
  };
};
