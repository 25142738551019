import store from '../store/store';
import { generateTokenApi } from '../api/ApiService';
import { LOGOUT } from '../constants';

export const validateToken = async () => {
  const data1 = store.getState();
  const accessTokenExpiry = data1.loginData?.loginSuccesData?.accessTokenExpiry;
  const currentTime = Math.floor(new Date().getTime() / 1000);
  const checkt = currentTime - 60;
  const response = await generateTokenApi();
  if (response?.response?.statusText === 'Unauthorized') {
    store.dispatch({ type: LOGOUT });
    window.location.href = '/';
  }
  return response;
};
