import { isEmpty } from 'lodash';
import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getExportTransactionAction,
  getFilterTypeActionFn,
  getPdfTransactionDetailAction,
  getTransactionActionFn,
  getTransactionByIdActionFn,
} from '../../redux/actions/transactionAction';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import exportFromJSON from 'export-from-json';
import ExportData from '../../redux/utilities/export';
import { renderToString } from 'react-dom/server';
import { jsPDF } from 'jspdf';
import { GET_PDF_TRANSACTIONS_DETAILS } from '../../redux/constants';

const MerchantTransactions = (props) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const [transactionDataArr, setTransactionDataArr] = useState([]);
  const [transactionDetailById, setTransactionDetailById] = useState([]);
  const [filters, setFilters] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isUserFilter, setIsUserFilter] = useState(false);
  const [isSort, setIsSort] = useState('""');
  const [customRange, setCustomRange] = useState('');
  const [isCurrentPage, setIsCurrentPage] = useState(1);
  const [isStatus, setIsStatus] = useState([]);
  const [isTransactionType, setIsTransactionType] = useState([]);
  const [isMethod, setIsMethod] = useState([]);
  const [isAmountType, setIsAmountType] = useState('');
  const [showCalender, setShowCalender] = useState(false);
  const [isCalenderDate, setIsCalenderDate] = useState('');
  const [isCalnder, setIsCalnder] = useState(true);
  const [tranactionExport, setTransactionExport] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchTrueORFalse, setSearchTrueORFalse] = useState(false);

  useEffect(() => {
    if (searchTrueORFalse == false) {
      getInitialData();
    }
  }, [searchTrueORFalse]);

  const getInitialData = () => {
    const formData = {
      accountType: 'Merchant',
      status: isStatus,
      type: isTransactionType,
      method: isMethod,
      sort: isAmountType, // High, Low, Recent
      time: isSort, //last_month, this_month, last_6_month
      custom_range: customRange, //If Date range is selected then time param should be empty
      page: 1, //default should be always 1
      search: searchTrueORFalse,
      searchText: searchText,
    };
    getTransactionsList(formData);
    getFilterList();
  };

  const clearFilter = () => {
    setIsStatus([]);
    setIsTransactionType([]);
    setIsMethod([]);
    setIsAmountType('');
  };

  const getTransactionsList = async (formData) => {
    try {
      const response = await dispatch(getTransactionActionFn(formData));
      const transactionResponse = response?.payload;
      if (transactionResponse) {
        if (transactionResponse?.status == 200) {
          setTransactionDataArr(transactionResponse?.data);
        } else {
          console.error(transactionResponse);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getFilterList = async () => {
    try {
      const response = await dispatch(getFilterTypeActionFn());
      const getFilterResponse = response?.payload;
      if (getFilterResponse) {
        if (getFilterResponse?.status == 200) {
          setFilters(getFilterResponse?.data);
        } else {
          console.error(getFilterResponse);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getTransactionById = async (id) => {
    try {
      const response = await dispatch(getTransactionByIdActionFn(id));
      const transactionResponse = response?.payload;
      if (transactionResponse) {
        if (transactionResponse?.status == 200) {
          setTransactionDetailById(transactionResponse?.data?.data);
          setShow(true);
        } else {
          console.error(transactionResponse);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getSearchData = async () => {
    try {
      if (searchText == '') {
        return false;
      } else {
        const formData = {
          accountType: 'Merchant',
          search: searchTrueORFalse,
          searchText: searchText,
          status: isStatus,
          type: isTransactionType,
          method: isMethod,
          sort: isAmountType, // High, Low, Recent
          time: isSort, //last_month, this_month, last_6_month
          custom_range: customRange, //If Date range is selected then time param should be empty
          page: isCurrentPage, //default should be always 1
        };
        const response = await dispatch(getTransactionActionFn(formData));
        const searchResponse = response?.payload;
        if (searchResponse) {
          if (searchResponse?.status == 200) {
            setTransactionDataArr(searchResponse?.data);
          } else {
            console.error(searchResponse);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearchTextChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value == '') {
      setSearchTrueORFalse(false);
    } else {
      setSearchTrueORFalse(true);
    }
  };

  const handleSearchEnterkey = (e) => {
    const key = e.key;
    if (key == 'Enter') {
      if (searchTrueORFalse == true) {
        getSearchData();
      }
    }
  };

  const onChangeStatusHandler = (e) => {
    if (e.target.checked) {
      setIsStatus([...isStatus, e.target.value]);
    } else {
      let remove = isStatus.indexOf(e.target.value);
      setIsStatus(isStatus.filter((_, i) => i !== remove));
    }
  };

  const onChangeTransactionType = (e) => {
    if (e.target.checked) {
      setIsTransactionType([...isTransactionType, e.target.value]);
    } else {
      let remove = isTransactionType.indexOf(e.target.value);
      setIsTransactionType(isTransactionType.filter((_, i) => i !== remove));
    }
  };

  const onChangeMethodHandler = (e) => {
    if (e.target.checked) {
      setIsMethod([...isMethod, e.target.value]);
    } else {
      let remove = isMethod.indexOf(e.target.value);
      setIsMethod(isMethod.filter((_, i) => i !== remove));
    }
  };

  const onChangeAmountTypeHandler = (e) => {
    setIsAmountType(e.target.value);
  };

  const sortHander = (e) => {
    if (e.target.value === 'custom_range') {
      setShowCalender(true);
    } else {
      setIsSort(e.target.value);
      const formData = {
        accountType: 'Merchant',
        status: isStatus,
        type: isTransactionType,
        method: isMethod,
        sort: isAmountType, // High, Low, Recent
        time: e.target.value, //last_month, this_month, last_6_month
        custom_range: customRange, //If Date range is selected then time param should be empty
        page: isCurrentPage, //default should be always 1
        search: searchTrueORFalse,
        searchText: searchText,
      };
      dispatch(getTransactionActionFn(formData));
      setShowCalender(false);
    }
  };

  const selectDate = (value) => {
    const rangeDate = moment(value[0]).format('Y-MM-DD') + ' ' + moment(value[1]).format('Y-MM-DD');

    const formData = {
      accountType: 'Merchant',
      status: isStatus,
      type: isTransactionType,
      method: isMethod,
      sort: isAmountType, // High, Low, Recent
      time: '', //last_month, this_month, last_6_month
      custom_range: rangeDate, //If Date range is selected then time param should be empty
      page: isCurrentPage, //default should be always 1
      search: searchTrueORFalse,
      searchText: searchText,
    };
    dispatch(getTransactionActionFn(formData));
    setIsCalenderDate(rangeDate);
    setIsCalnder(false);
  };

  const calenderToggle = () => {
    setIsCalnder(!isCalnder);
  };

  const applyFilter = (e) => {
    e.preventDefault();
    const formData = {
      accountType: 'Merchant',
      status: isStatus,
      type: isTransactionType,
      method: isMethod,
      sort: isAmountType, // High, Low, Recent
      time: isSort, //last_month, this_month, last_6_month
      page: isCurrentPage, //default should be always 1
      search: searchTrueORFalse,
      searchText: searchText,
    };
    dispatch(getTransactionActionFn(formData));
    setIsUserFilter(false);

    // closing filter box
    const className = document.getElementsByClassName('dropdown-menu show');
    className[0].className = 'dropdown-menu';
  };

  // ============== export data to excel sheet ==================
  const exportData = async () => {
    try {
      const formData = {
        accountType: 'Merchant',
        status: isStatus,
        type: isTransactionType,
        method: isMethod,
        sort: isAmountType, // High, Low, Recent
        time: isSort, //last_month, this_month, last_6_month
        custom_range: customRange, //If Date range is selected then time param should be empty
        page: isCurrentPage, //default should be always 1
        export: true,
        search: searchTrueORFalse,
        searchText: searchText,
      };
      const exportTransactionData = await dispatch(getExportTransactionAction(formData));
      const response = exportTransactionData?.payload;
      if (response) {
        if (response?.status) {
          setTransactionExport(response?.result);
          const data = response && response?.result;
          const fileName = 'download';
          // const exportType =  exportFromJSON.types.xls
          const exportType = exportFromJSON.types.xlsx;
          exportFromJSON({ data, fileName, exportType });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // ========== export pdf =============================
  const exportPdf = async (transactionId) => {
    try {
      const exportPdfApiResponse = await dispatch(getPdfTransactionDetailAction(transactionId));
      const response = exportPdfApiResponse?.payload;
      if (response) {
        if (response?.status == true) {
          const transDetails = response?.data?.transactionDetails;
          const paymentDetails = response?.data?.paymentDetails;
          if (transDetails) {
            downloadPdf(transDetails, paymentDetails);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  //=========== download pdf ====================================
  const downloadPdf = (transDetails, paymentDetails) => {
    if (transDetails) {
      const doc = new jsPDF('p', 'pt', 'legal');
      let a = renderToString(<ExportData data={transDetails} schoolData={paymentDetails} />);

      doc.html(a, {
        callback: function (doc) {
          doc.save('transactionDetails.pdf');
          dispatch({ type: GET_PDF_TRANSACTIONS_DETAILS, payload: '' });
        },
      });
    }
  };

  //=============Pagination====================Start==========
  const paginationHander = (pageNumber) => {
    setIsCurrentPage(pageNumber);
    let formData = getFilter(pageNumber);
    dispatch(getTransactionActionFn(formData));
  };

  const paginationList = () => {
    const pageNumbers = [];
    for (var i = 1; i <= transactionDataArr?.totalPage; i++) {
      pageNumbers.push(i);
    }
    if (transactionDataArr?.totalPage > 3) {
    }
    const renderPageNumbers = pageNumbers.map((number) => {
      if (
        number === transactionDataArr?.currentPage - 2 ||
        number === transactionDataArr?.currentPage + 2
      ) {
        return <span>...</span>;
      } else if (
        number < 2 ||
        number === pageNumbers.length ||
        transactionDataArr?.currentPage === number ||
        transactionDataArr?.currentPage === number - 1 ||
        transactionDataArr?.currentPage === number + 1
      ) {
        return (
          <li key={number} onClick={(i) => paginationHander(number)}>
            <a className={transactionDataArr?.currentPage == number ? 'active' : ''}>{number}</a>
          </li>
        );
      }
    });
    return (
      <ul>
        <li>
          <a className="nxt" onClick={() => firstPaginationHander()}>
            <i className="fa fa-angle-double-left" aria-hidden="true" />
          </a>
        </li>
        <li>
          <a className="nxt" onClick={() => previousPaginationHander()}>
            <i className="fa fa-angle-left" aria-hidden="true" />
          </a>
        </li>
        {renderPageNumbers}
        <li>
          <a className="nxt" onClick={() => nextPaginationHander(transactionDataArr?.totalPage)}>
            <i className="fa fa-angle-right" aria-hidden="true" />
          </a>
        </li>
        <li>
          <a className="nxt" onClick={() => lastPaginationHander(transactionDataArr?.totalPage)}>
            <i className="fa fa-angle-double-right" aria-hidden="true" />
          </a>
        </li>
      </ul>
    );
  };

  const getFilter = (page) => {
    const formData = {
      accountType: 'Merchant',
      status: isStatus,
      type: isTransactionType,
      method: isMethod,
      sort: isAmountType, // High, Low, Recent
      time: isSort, //last_month, this_month, last_6_month
      page: page,
      search: searchTrueORFalse,
      searchText: searchText,
    };
    return formData;
  };

  const nextPaginationHander = (pageNumber) => {
    if (isCurrentPage !== pageNumber) {
      const p = isCurrentPage + 1;
      setIsCurrentPage(p);
      let formData = getFilter(p);
      getTransactionsList(formData);
    }
  };

  const previousPaginationHander = () => {
    if (isCurrentPage > 1) {
      const p = isCurrentPage - 1;
      setIsCurrentPage(p);
      let formData = getFilter(p);
      getTransactionsList(formData);
    }
  };

  const firstPaginationHander = () => {
    const p = 1;
    setIsCurrentPage(p);
    let formData = getFilter(p);
    getTransactionsList(formData);
  };

  const lastPaginationHander = (pageNumber) => {
    const p = pageNumber;
    setIsCurrentPage(p);
    let formData = getFilter(p);
    getTransactionsList(formData);
  };

  //===================Pagination=======================End===================

  const showTransactionData = (id) => {
    if (id) {
      getTransactionById(id);
    }
  };

  const transactionDetails = () => {
    if (transactionDetailById?.paymentDetails?.merchantType === 'Travel') {
      return (
        <>
          <div className="col-sm-6 tarnsDetails">
            <span className="popTitle">Method</span>
            <span className="popDesc">{transactionDetailById?.transactionDetails?.method}</span>
          </div>
          <div className="col-sm-6 tarnsDetails">
            <span className="popTitle">Type</span>
            <span className="popDesc">{transactionDetailById?.transactionDetails?.type}</span>
          </div>
          <div className="col-sm-6 tarnsDetails">
            <span className="popTitle">PRN Number</span>
            <span className="popDesc"></span>
          </div>
          <div className="col-sm-6 tarnsDetails">
            <span className="popTitle">Payment details</span>
            <span className="popDesc">
              {transactionDetailById?.transactionDetails?.paymentThrough}
            </span>
          </div>
        </>
      );
    } else if (transactionDetailById?.paymentDetails?.merchantType === 'Schools') {
      return (
        <>
          <div className="col-sm-6 tarnsDetails">
            <span className="popTitle">Method</span>
            <span className="popDesc">{transactionDetailById?.transactionDetails?.method}</span>
          </div>
          <div className="col-sm-6 tarnsDetails">
            <span className="popTitle">Type</span>
            <span className="popDesc">{transactionDetailById?.transactionDetails?.type}</span>
          </div>
          <div className="col-sm-6 tarnsDetails">
            <span className="popTitle">Student Number</span>
            <span className="popDesc">
              {transactionDetailById?.paymentDetails?.data?.student_number}
            </span>
          </div>
          <div className="col-sm-6 tarnsDetails">
            <span className="popTitle">Payment details</span>
            <span className="popDesc">
              {transactionDetailById?.transactionDetails?.paymentThrough}
            </span>
          </div>
          <div className="col-sm-6 tarnsDetails">
            <span className="popTitle">Student Name</span>
            <span className="popDesc">
              {transactionDetailById?.paymentDetails?.data?.student_name}
            </span>
          </div>
          <div className="col-sm-6 tarnsDetails">
            <span className="popTitle">Fee Details</span>
            <span className="popDesc">
              {transactionDetailById?.paymentDetails?.data?.fee_detail}
            </span>
          </div>
          <div className="col-sm-6 tarnsDetails">
            <span className="popTitle">Institute</span>
            <span className="popDesc">
              {transactionDetailById?.paymentDetails?.data?.institute}
            </span>
          </div>
          <div className="col-sm-6 tarnsDetails">
            <span className="popTitle">Location</span>
            <span className="popDesc">{transactionDetailById?.paymentDetails?.data?.location}</span>
          </div>
        </>
      );
    }
  };

  const renderTableData = () => {
    return (
      !isEmpty(transactionDataArr) &&
      transactionDataArr?.result?.map((item, index) => {
        const {
          transactionId,
          transactionDate,
          type,
          receiverAvtar,
          senderAvtar,
          senderName,
          amount,
          transactionStatus,
          method,
          receiverName,
          action,
        } = item;
        return (
          <tr>
            <td>
              <label className="custom-check">
                <input type="checkbox" id="transaction-checkbox" />
                <span className="checkmark"></span>
              </label>
            </td>
            <td onClick={() => showTransactionData(transactionId)}>#{transactionId}</td>
            <td>
              <div className="date">{transactionDate}</div>
            </td>
            <td>
              <div className="sender1111">
                <span className="sender-img">
                  <img
                    src={
                      senderAvtar
                        ? senderAvtar
                        : 'https://pocketi-api.s3-ap-southeast-1.amazonaws.com/user_avatar.png'
                    }
                    alt=""
                  />
                </span>
                <span className="sender-txt">{senderName ? senderName : 'NA'}</span>
              </div>
            </td>
            <td>
              <div className="sender1111">
                <span className="sender-img">
                  <img
                    src={
                      receiverAvtar
                        ? receiverAvtar
                        : 'https://pocketi-api.s3-ap-southeast-1.amazonaws.com/user_avatar.png'
                    }
                    alt=""
                  />
                </span>
                <span className="sender-txt">{receiverName ? receiverName : 'NA'}</span>
              </div>
            </td>
            <td>GH₵ {amount}</td>
            <td>{transactionStatus}</td>
            <td>{method}</td>
            <td>{type}</td>
            <td>
              <a
                className="action-link"
                onClick={() => {
                  exportPdf(transactionId);
                }}
                style={{ cursor: 'pointer' }}
              >
                <i className="icon-icon-download"></i>
              </a>
            </td>
          </tr>
        );
      })
    );
  };

  const handleSelectAll = (e) => {
    const checkbox = document.getElementById('selectall-checkbox');
    const transCheckbox = document.querySelectorAll('#transaction-checkbox');
    if (checkbox.checked) {
      for (var chbox of transCheckbox) {
        chbox.checked = true;
      }
    } else {
      for (var chbox of transCheckbox) {
        chbox.checked = false;
      }
    }
  };

  return (
    <>
      <div className="sec-block alt">
        <div className="block-single auto">
          <div className="block-heading">
            <h2>Transactions</h2>
            <div className="table-btn">
              <div className="cm_search search-wrap clearable mr-3">
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => {
                    handleSearchTextChange(e);
                  }}
                  onKeyPress={handleSearchEnterkey}
                  className="form-control"
                  placeholder="Search"
                />
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  onClick={() => {
                    getSearchData();
                  }}
                />
                <i className="clearable__clear icon-icon-close2" />
              </div>
              <div className="dropdown inline drop-filter">
                <a
                  className="dropdown-toggle link"
                  style={{ cursor: 'pointer' }}
                  id="filterDropDown"
                  data-toggle="dropdown"
                >
                  <i className="icon-icon-filter" aria-hidden="true"></i> Filters
                </a>
                <div className="dropdown-menu" aria-labelledby="filterDropDown">
                  <form onSubmit={(e) => applyFilter(e)}>
                    <div>
                      <input
                        type="reset"
                        className="clear-filter"
                        value="Clear all"
                        onClick={() => clearFilter()}
                      />

                      <div className="row modified">
                        <div className="col-5">
                          <div className="filter-single">
                            <h4>By status</h4>
                            <label className="custom-check">
                              In Process
                              <input
                                type="checkbox"
                                value="In Process"
                                name="status"
                                onClick={(e) => onChangeStatusHandler(e)}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="custom-check">
                              Credited
                              <input
                                type="checkbox"
                                value="Credited"
                                name="status"
                                onClick={(e) => onChangeStatusHandler(e)}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="custom-check">
                              Failed
                              <input
                                type="checkbox"
                                value="Failed"
                                name="status"
                                onClick={(e) => onChangeStatusHandler(e)}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="custom-check">
                              Debited
                              <input
                                type="checkbox"
                                value="Debited"
                                name="status"
                                onClick={(e) => onChangeStatusHandler(e)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-7">
                          <div className="filter-single">
                            <h4>By transaction type</h4>
                            <div className="row modified">
                              <div className="col-12">
                                {filters?.data?.length > 0 &&
                                  filters?.data.map((filter) => {
                                    return (
                                      <label className="custom-check">
                                        {filter.type}
                                        <input
                                          type="checkbox"
                                          name="transaction_type"
                                          value={filter.type}
                                          onClick={(e) => onChangeTransactionType(e)}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row modified">
                        <div className="col-5">
                          <div className="filter-single">
                            <h4>By method</h4>
                            <label className="custom-check">
                              Payment
                              <input
                                type="checkbox"
                                name="payment_method"
                                value="Payment"
                                onClick={(e) => onChangeMethodHandler(e)}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="custom-check">
                              Request
                              <input
                                type="checkbox"
                                name="payment_method"
                                value="Request"
                                onClick={(e) => onChangeMethodHandler(e)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-7">
                          <div className="filter-single">
                            <h4>Sort by</h4>
                            <label className="custom-check">
                              Most recent
                              <input
                                type="radio"
                                name="amount_type"
                                value="Recent"
                                onClick={(e) => onChangeAmountTypeHandler(e)}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="custom-check">
                              Amount: from low to high
                              <input
                                type="radio"
                                name="amount_type"
                                value="Low"
                                onClick={(e) => onChangeAmountTypeHandler(e)}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="custom-check">
                              Amount: from high to low
                              <input
                                type="radio"
                                name="amount_type"
                                value="High"
                                onClick={(e) => onChangeAmountTypeHandler(e)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <input
                        className="btn btn-block green-btn"
                        type="submit"
                        name
                        defaultValue="Apply"
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="custom-select-wrap alt">
                <select
                  className="custom-select"
                  name="state"
                  onChange={(e) => sortHander(e)}
                  style={{
                    background: '#f4f4f4',
                    border: 'none',
                    fontSize: '12px',
                    padding: '0 10px',
                    maxWidth: '115px',
                    margin: '0 5px',
                    cursor: 'pointer',
                  }}
                >
                  <option value="''">All time</option>
                  <option value="this_month">This month</option>
                  <option value="last_month">Last month</option>
                  <option value="last_6_month">6 months</option>
                  <option value="custom_range">Custom range</option>
                </select>
              </div>
              {showCalender && (
                <>
                  <div className="custom-select-wrap alt">
                    <div className="inpIcon">
                      <input
                        className="form-control"
                        data-provide="datepicker"
                        value={isCalenderDate}
                        onClick={() => calenderToggle()}
                      />
                      <i className="fa fa-calendar-o" aria-hidden="true" />
                    </div>
                    {isCalnder && (
                      <Calendar
                        onChange={(value) => selectDate(value)}
                        next2Label={null}
                        prev2Label={null}
                        selectRange={true}
                        returnValue="range"
                      />
                    )}
                  </div>
                </>
              )}
              <a className="link green" onClick={exportData} style={{ cursor: 'pointer' }}>
                <i className="icon-icon-download"></i> Export
              </a>
            </div>
          </div>
          <div className="transaction-main">
            <div className="transaction-table">
              <div className="table-responsive">
                <table className="table theme-table">
                  <tr>
                    <th>
                      <label className="custom-check">
                        <input type="checkbox" id="selectall-checkbox" onChange={handleSelectAll} />
                        <span className="checkmark"></span>
                      </label>
                    </th>
                    <th>Transaction ID</th>
                    <th>Date</th>
                    <th>Sender</th>
                    <th>Receiver</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Method</th>
                    <th>Type</th>
                    <th style={{ width: '80px' }}>Action</th>
                  </tr>
                  {renderTableData()}
                </table>
              </div>
            </div>
          </div>

          <div className="site-pagination">{paginationList()}</div>
        </div>
      </div>

      {/* modal first */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        className="modal-dialog-centered common-modal fade"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Transaction ID {transactionDetailById?.transactionDetails?.transactionId}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <i className="icon-icon-close2"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="trans-details">
            <div className="row modified">
              <div className="col-sm-9">
                <div className="trans-details-left">
                  <span className="transDate">
                    {transactionDetailById?.transactionDetails?.transactionDate}
                  </span>
                  <span className="transName">
                    GH₵ {transactionDetailById?.transactionDetails?.amount}
                  </span>
                  <span className="transStat">
                    {transactionDetailById?.transactionDetails?.transactionStatus}
                  </span>
                  <div className="row modified">
                    <div className="col-lg-6 tarnsDetails">
                      <span className="popTitle">Sender</span>
                      <div className="sender">
                        <span className="sender-img">
                          <img
                            src={transactionDetailById?.transactionDetails?.senderAvtar}
                            alt=""
                          />
                        </span>
                        <span className="sender-txt">
                          {transactionDetailById?.transactionDetails?.senderName} <br />{' '}
                          <a href="#">{transactionDetailById?.transactionDetails?.senderPhone}</a>
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6 tarnsDetails">
                      <span className="popTitle">Receiver</span>
                      <div className="sender">
                        <span className="sender-img-comp">
                          <img
                            src={transactionDetailById?.transactionDetails?.receiverAvtar}
                            alt=""
                          />
                        </span>
                        <span className="sender-txt">
                          {transactionDetailById?.transactionDetails?.receiverName} <br />{' '}
                          <a href="#">
                            {transactionDetailById?.transactionDetails?.receiverPhone}{' '}
                          </a>
                        </span>
                      </div>
                    </div>
                    {transactionDetails()}
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="trans-details-right">
                  <ul class="transLink">
                    <li>
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          exportPdf(transactionDetailById?.transactionDetails?.transactionId);
                        }}
                      >
                        <i class="icon-icon-download"></i> Export
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MerchantTransactions;
