import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ArrowDown from '../../assets/images/arrow-down.svg';
import ArrowUp from '../../assets/images/arrow-up.svg';
import { userStatsActionFn } from '../../redux/actions/userDetailsAction';
import PaymentvsRequest from './paymentvsrequest';
import TransactionHistory from './transaction-history';
import Header from '../common/header';
import {
  getGraphScaleValueArr,
  getGraphValueArr,
  getPercentageChange,
} from '../../redux/utilities';
import _ from 'lodash';

const Dashboard = (props) => {
  const dispatch = useDispatch();

  const [paymentCount, setPaymentCount] = useState(0);
  const [prevPaymentCount, setPrevPaymentCount] = useState(0);
  const [reqCount, setReqCount] = useState(0);
  const [prevReqCount, setPrevReqCount] = useState(0);
  const [receiveCount, setReceiveCount] = useState(0);
  const [prevReceiveCount, setPrevReceiveCount] = useState(0);
  const [sentAmount, setSentAmount] = useState(0);
  const [prevSentAmount, setPrevSentAmount] = useState(0);
  //======================= Small Graph ===============================
  const [valueArr, setValueArr] = useState({});
  const [graphScaleValue, setGraphScaleValue] = useState({});
  const [paymentCountObject, setPaymentCountObject] = useState({});
  const [requestCountObject, setRequestCountObject] = useState({});
  const [totalReceiveCountObject, setTotalReceiveCountObject] = useState({});
  const [totalSentCountObject, setTotalSentCountObject] = useState({});

  useEffect(() => {
    getUserWalletDetail();
  }, []);

  const getUserWalletDetail = async () => {
    try {
      const response = await dispatch(userStatsActionFn('Individual'));
      const getUserWalletResponse = response?.payload;
      if (getUserWalletResponse) {
        if (getUserWalletResponse?.status == 200) {
          const { currentMonth, lastMonth } = getUserWalletResponse.data.result;
          // currentMonth
          setPaymentCount(currentMonth?.paymentCount);
          setReqCount(currentMonth?.requestCount);
          setReceiveCount(currentMonth?.totalReceive);
          setSentAmount(currentMonth?.totalSent);
          // lastMonth
          setPrevPaymentCount(lastMonth?.paymentCount);
          setPrevReqCount(lastMonth?.requestCount);
          setPrevReceiveCount(lastMonth?.totalReceive);
          setPrevSentAmount(lastMonth?.totalSent);
          // Small Graph
          getSmallGraphValue(getUserWalletResponse.data.result);
        } else {
          console.error(getUserWalletResponse);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getSmallGraphValue = async (data) => {
    try {
      const response = await getGraphValueArr(data);
      setValueArr(response);
      const values = await getGraphScaleValueArr(response);
      setGraphScaleValue(values);
      setPaymentCountObject(values['paymentCount']);
      setRequestCountObject(values['requestCount']);
      setTotalReceiveCountObject(values['totalReceive']);
      setTotalSentCountObject(values['totalSent']);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Header />
      <section class="dash-wrap">
        <div class="dash-stat">
          <div class="row modified">
            <div class="col-lg-3 col-sm-6">
              <div class="dash-stat-single">
                <h2>{paymentCount}</h2>
                <p>payments this month</p>
                <div class="dash-stat-graph">
                  {getPercentageChange(prevPaymentCount, paymentCount) >= 0 ? (
                    <div className="stat-percentage">
                      +{getPercentageChange(prevPaymentCount, paymentCount, 'absTrue')}
                      % <img src={ArrowUp} alt="" />{' '}
                    </div>
                  ) : (
                    <div className="stat-percentage down">
                      -{getPercentageChange(prevPaymentCount, paymentCount, 'absTrue')}
                      % <img src={ArrowDown} alt="" />{' '}
                    </div>
                  )}

                  <div class="stat-percentage-graph">
                    {paymentCountObject ? (
                      <ul>
                        <li>
                          <span
                            style={{
                              height: `${paymentCountObject['thirdLastMonth'] > 0 ? paymentCountObject['thirdLastMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                        <li>
                          <span
                            style={{
                              height: `${paymentCountObject['secondLastMonth'] > 0 ? paymentCountObject['secondLastMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                        <li>
                          <span
                            style={{
                              height: `${paymentCountObject['lastMonth'] > 0 ? paymentCountObject['lastMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className={`${getPercentageChange(prevPaymentCount, paymentCount) >= 0 ? 'graph-up' : 'graph-down'}`}
                            style={{
                              height: `${paymentCountObject['currentMonth'] > 0 ? paymentCountObject['currentMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="dash-stat-single">
                <h2>{reqCount}</h2>
                <p>requests this month</p>
                <div class="dash-stat-graph">
                  {getPercentageChange(prevReqCount, reqCount) >= 0 ? (
                    <div className="stat-percentage">
                      +{getPercentageChange(prevReqCount, reqCount, 'absTrue')}
                      % <img src={ArrowUp} alt="" />{' '}
                    </div>
                  ) : (
                    <div className="stat-percentage down">
                      -{getPercentageChange(prevReqCount, reqCount, 'absTrue')}
                      % <img src={ArrowDown} alt="" />{' '}
                    </div>
                  )}

                  <div class="stat-percentage-graph">
                    {requestCountObject ? (
                      <ul>
                        <li>
                          <span
                            style={{
                              height: `${requestCountObject['thirdLastMonth'] > 0 ? requestCountObject['thirdLastMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                        <li>
                          <span
                            style={{
                              height: `${requestCountObject['secondLastMonth'] > 0 ? requestCountObject['secondLastMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                        <li>
                          <span
                            style={{
                              height: `${requestCountObject['lastMonth'] > 0 ? paymentCountObject['lastMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className={`${getPercentageChange(prevReqCount, reqCount) >= 0 ? 'graph-up' : 'graph-down'}`}
                            style={{
                              height: `${requestCountObject['currentMonth'] > 0 ? requestCountObject['currentMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="dash-stat-single">
                <h2>GH₵ {receiveCount}</h2>
                <p>received this month</p>
                <div class="dash-stat-graph">
                  {getPercentageChange(prevReceiveCount, receiveCount) >= 0 ? (
                    <div className="stat-percentage">
                      +{getPercentageChange(prevReceiveCount, receiveCount, 'absTrue')}
                      % <img src={ArrowUp} alt="" />{' '}
                    </div>
                  ) : (
                    <div className="stat-percentage down">
                      -{getPercentageChange(prevReceiveCount, receiveCount, 'absTrue')}
                      % <img src={ArrowDown} alt="" />{' '}
                    </div>
                  )}
                  <div class="stat-percentage-graph">
                    {totalReceiveCountObject ? (
                      <ul>
                        <li>
                          <span
                            style={{
                              height: `${totalReceiveCountObject['thirdLastMonth'] > 0 ? totalReceiveCountObject['thirdLastMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                        <li>
                          <span
                            style={{
                              height: `${totalReceiveCountObject['secondLastMonth'] > 0 ? totalReceiveCountObject['secondLastMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                        <li>
                          <span
                            style={{
                              height: `${totalReceiveCountObject['lastMonth'] > 0 ? totalReceiveCountObject['lastMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className={`${getPercentageChange(prevReceiveCount, receiveCount) >= 0 ? 'graph-up' : 'graph-down'}`}
                            style={{
                              height: `${totalReceiveCountObject['currentMonth'] > 0 ? totalReceiveCountObject['currentMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="dash-stat-single">
                <h2>GH₵ {sentAmount}</h2>
                <p>sent this month</p>
                <div class="dash-stat-graph">
                  {getPercentageChange(prevSentAmount, sentAmount) >= 0 ? (
                    <div className="stat-percentage">
                      +{getPercentageChange(prevSentAmount, sentAmount, 'absTrue')}
                      % <img src={ArrowUp} alt="" />{' '}
                    </div>
                  ) : (
                    <div className="stat-percentage down">
                      -{getPercentageChange(prevSentAmount, sentAmount, 'absTrue')}
                      % <img src={ArrowDown} alt="" />{' '}
                    </div>
                  )}
                  <div class="stat-percentage-graph">
                    {totalSentCountObject ? (
                      <ul>
                        <li>
                          <span
                            style={{
                              height: `${totalSentCountObject['thirdLastMonth'] > 0 ? totalSentCountObject['thirdLastMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                        <li>
                          <span
                            style={{
                              height: `${totalSentCountObject['secondLastMonth'] > 0 ? totalSentCountObject['secondLastMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                        <li>
                          <span
                            style={{
                              height: `${totalSentCountObject['lastMonth'] > 0 ? totalSentCountObject['lastMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className={`${getPercentageChange(prevSentAmount, sentAmount) >= 0 ? 'graph-up' : 'graph-down'}`}
                            style={{
                              height: `${totalSentCountObject['currentMonth'] > 0 ? totalSentCountObject['currentMonth'] : '2'}%`,
                            }}
                          ></span>
                        </li>
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PaymentvsRequest />
        <TransactionHistory />
      </section>
    </>
  );
};
export default Dashboard;
