import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  currentUserActionFn,
  switchAccountActionFn,
  userDetailsActionFn,
} from '../../redux/actions/userDetailsAction';

export const UserSwitch = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const CurrentUser = useSelector((state) => state.UserData.currentuser);
  const UserDetails = useSelector((state) => state.loginData);

  const [isShowDropDown, setisShowDropDown] = useState(false);
  const handleShowDropdown = () =>
    setisShowDropDown((prevState) => {
      return !prevState;
    });
  const [userSwitched, setUserSwitched] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [profilePicture, setProfilePicture] = useState('');

  useEffect(() => {
    getUserDetails();
  }, [userSwitched]);

  const switchAccount = async (account) => {
    handleShowDropdown();
    let data = { accountType: account };
    const switchAccSuccessResponse = await dispatch(switchAccountActionFn(data));
    const response = switchAccSuccessResponse?.payload;
    handleCurrentUser(response);
    setUserSwitched(false);
  };

  const getUserDetails = async () => {
    try {
      const response = await dispatch(userDetailsActionFn());
      const userDataResponse = response?.payload;

      if (userDataResponse) {
        setUserDetails(userDataResponse?.data);
        setName(userDataResponse?.data?.user?.name);
        setCompanyName(userDataResponse?.data?.user?.companyName);
        setCompanyLogo(userDataResponse?.data?.user?.companyLogo);
        setProfilePicture(userDataResponse?.data?.user?.profilePicture);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleCurrentUser = (switched) => {
    if (switched?.status === true) {
      if (switched?.message === 'Account type selected to Individual') {
        dispatch(currentUserActionFn('Individual'));
        history.push('/user-dashboard');
      } else if (switched?.message === 'Account type selected to Business') {
        dispatch(currentUserActionFn('Business'));
        history.push('/business-dashboard');
      }
    }
  };

  if (CurrentUser?.data === 'Individual') {
    return (
      <>
        <div className="dropdown dropUser">
          <div
            className="dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
          >
            {UserDetails?.loginSuccesData?.profilePicture ? (
              <img src={UserDetails?.loginSuccesData?.profilePicture} alt="" />
            ) : (
              <img src={window.location.origin + '/userimg.png'} alt="" />
            )}

            <h2>{UserDetails?.loginSuccesData?.name}</h2>
            <p>Individual</p>
          </div>

          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <Link className="dropdown-item " onClick={() => switchAccount('Business')}>
              {UserDetails?.loginSuccesData?.companyLogo ? (
                <img src={UserDetails?.loginSuccesData?.companyLogo} alt="" />
              ) : (
                <img src={window.location.origin + '/userimg.png'} alt="" />
              )}
              <h2>{UserDetails?.loginSuccesData?.companyName}</h2>

              <p>Business</p>
            </Link>
          </div>
        </div>
      </>
    );
  } else if (CurrentUser?.data === 'Business') {
    return (
      <>
        <div className="dropdown dropUser">
          <div
            className="dropdown-toggle "
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
          >
            {UserDetails?.loginSuccesData?.companyLogo ? (
              <img src={UserDetails?.loginSuccesData?.companyLogo} alt="" aria-hidden="true" />
            ) : (
              <img src={window.location.origin + '/userimg.png'} alt="" aria-hidden="true" />
            )}

            <h2>{UserDetails?.loginSuccesData?.companyName}</h2>

            <p>Business</p>
          </div>

          <div className="dropdown-menu " aria-labelledby="dropdownMenuButton">
            <Link className="dropdown-item" onClick={() => switchAccount('Individual')}>
              {UserDetails.loginSuccesData.profilePicture ? (
                <img src={UserDetails.loginSuccesData.profilePicture} alt="" />
              ) : (
                <img src={window.location.origin + '/userimg.png'} alt="" />
              )}
              <h2>{UserDetails?.loginSuccesData?.name}</h2>
              <p>Individual</p>
            </Link>
          </div>
        </div>
      </>
    );
  } else if (CurrentUser?.data === 'Merchant') {
    return (
      <>
        <div className="dropdown dropUser">
          <div className="dropdown-toggle merchant-toggle" type="button">
            {UserDetails?.loginSuccesData?.profilePicture ? (
              <img src={UserDetails?.loginSuccesData?.profilePicture} alt="" />
            ) : (
              <img src={window.location.origin + '/userimg.png'} alt="" />
            )}

            <h2>{UserDetails?.loginSuccesData?.name}</h2>
            <p>Merchant</p>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};
