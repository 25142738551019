const {
  GETTRANSACTIONS,
  TRANSACTION,
  TOPRECEIVERS,
  PAYMENTVSREQUEST,
  TRANSACTIONTYPE,
  FILTERTYPE,
  TOPSENDERS,
  REVENUEGRAPH,
  MERCHANTSTATS,
  GET_PDF_TRANSACTIONS_DETAILS,
} = require('../constants');

const initialState = {
  transactions: {},
  transactionDetail: {},
  topreceivers: {},
  paymentvsrequest: {},
  transactiontype: {},
  filtertypes: {},
  topsenders: {},
  revenuegraph: {},
  merchantStats: {},
  transactionPdfDetails: {},
};

const Transactions = (state = initialState, action) => {
  switch (action.type) {
    case GETTRANSACTIONS:
      return {
        ...state,
        transactions: action?.payload,
      };

    case TRANSACTION:
      return {
        ...state,
        transactionDetail: action?.payload,
      };

    case TOPRECEIVERS:
      return {
        ...state,
        topreceivers: action?.payload,
      };
    case TOPSENDERS:
      return {
        ...state,
        topsenders: action?.payload,
      };
    case PAYMENTVSREQUEST:
      return {
        ...state,
        paymentvsrequest: action?.payload,
      };
    case TRANSACTIONTYPE:
      return {
        ...state,
        transactiontype: action?.payload,
      };
    case FILTERTYPE:
      return {
        ...state,
        filtertypes: action?.payload,
      };

    case REVENUEGRAPH:
      return {
        ...state,
        revenuegraph: action?.payload,
      };
    case MERCHANTSTATS:
      return {
        ...state,
        merchantStats: action?.payload,
      };

    case GET_PDF_TRANSACTIONS_DETAILS:
      return {
        ...state,
        transactionPdfDetails: action?.payload,
      };

    default:
      return state;
  }
};
export default Transactions;
