import React from 'react';
import { Loader } from './components/loader';
import Routes from './routes/routes';

function App() {
  return (
    <div>
      <div className="page-wrapper">
        <Routes />
      </div>
      <Loader />
    </div>
  );
}
export default App;
