import { axVal } from './axiosApi';

export const AuthApi = (data) => {
  return axVal().post('auth/web_login', data);
};

export const SwitchApi = (data) => {
  return axVal().post('user/account_type', data);
};

export const GetOperatorList = () => {
  return axVal().get('cms/network/list');
};

export const GetCallingCode = () => {
  return axVal().get('cms/country/calling_code');
};

export const GetUserDetails = () => {
  return axVal().get('user/details');
};

export const LoginApi = (data) => {
  return axVal().post('auth/verify_otp', data);
};

export const generateTokenApi = () => {
  return axVal(true)
    .post('auth/generate_token')
    .then((response) => {
      return response;
    })
    .catch(({ ...error }) => {
      return error;
    });
};

// get transactions

export const getTransactions = (data) => {
  return axVal().post('transaction/user/all', data);
};

export const transactionsByType = (data) => {
  return axVal().get(`transaction/user/transaction_by_type/${data}`);
};
export const revenueGraph = (data) => {
  return axVal().get(`transaction/user/revenue_graph?month=${data}`);
};
export const FilterTypes = () => {
  return axVal().get('merchant/list/type');
};

export const getStats = (account_type) => {
  return axVal().get(`transaction/user/get_count?account_type=${account_type}`);
};

export const getTransactionById = (id) => {
  return axVal().get(`transaction/${id}`);
};

export const getTopReceiver = () => {
  return axVal().get('transaction/user/top_receivers');
};
export const getTopSenders = () => {
  return axVal().get('transaction/user/top_senders');
};
export const paymentvsRequest = (data) => {
  return axVal().get(
    `transaction/graph/payment_vs_request?account_type=${data.account_type}&month=${data.month}`,
  );
};
export const merchantTransactionStats = (month) => {
  return axVal().get(`transaction/merchant/statistics_graph?month=${month}`);
};
